import React from 'react';
import Layout from '../components/Layout/';
import Seo from '../components/Seo/';
import Homepage from '../components/Homepage/';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" keywords={[`social media automation`, `health coach`, `social engagement`]} />
    <Homepage quotes={data.allPrismicQuote.edges} />
  </Layout>
);

export const query = graphql`
  {
    allPrismicQuote {
      edges {
        node {
          data {
            content {
              html
            }
            name {
              text
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;
