import { Link } from 'gatsby';
import React from 'react';
import Quotes from './quotes';
import PropTypes from 'prop-types';

import ImgHeroLike from './heroLike.svg';
import ImgHeroWoman from './heroWoman.png';
import ImgHeroBg from './heroBg.svg';
import IllustrationsClients from './illustrations__clients.svg';
import IllustrationsFollowing from './illustrations__following.svg';
import IllustrationsReach from './illustrations__reach.svg';
import EngagementBg from './engagement__bg.svg';

const Homepage = ({ quotes }) => (
  <main>
    <div className="hero hero--home">
      <div className="wrapper">
        <h1>Accelerate your social media marketing and grow your revenue</h1>
        <p>Specialized social media automation for <strong>health coaches</strong> who want to target thier audience.</p>
        <Link to="/get-started" className="cta cta--primary">Get Started</Link>
      </div>
      <div className="hero--home__img">
        <img src={ImgHeroLike} alt="" width="194" height="189" className="hero--home__img__like" />
        <img src={ImgHeroWoman} alt="" width="510" height="490" className="hero--home__img__person" />
        <img src={ImgHeroBg} alt="" width="1271" height="490" className="hero--home__img__bg" />
      </div>
    </div>
    <section className="engagement">
      <div className="wrapper">
        <h2>Social engagement matters</h2>
        <p>Your potential clients are on social media and they are looking for an authority to teach them how to live healthy lives. Using social media, we help those viewers to be engaged with your brand, become buyers of your services, and be loyal followers.</p>
        <Link to="/get-started" className="link">See our offerings</Link>
      </div>
      <img src={EngagementBg} alt="" className="engagement__bg" />
    </section>
    <section className="what-we-do">
      <div className="wrapper">
        <div className="columns columns--three">
          <div className="column">
            <img src={IllustrationsFollowing} alt="" className="what-we-do__img" />
            <h3>Grow your following</h3>
            <p>Gain more followers without sacrificing your valuable time.</p>
          </div>
          <div className="column">
            <img src={IllustrationsReach} alt="" className="what-we-do__img" />
            <h3>Expand your reach</h3>
            <p>Increase the number of people who will see your message.</p>
          </div>
          <div className="column">
            <img src={IllustrationsClients} alt="" className="what-we-do__img" />
            <h3>Attract more clients</h3>
            <p>Have engaging messaging that turns viewers into customers.</p>
          </div>
        </div>
      </div>
    </section>
    <Quotes quotes={quotes} />
    <section className="section-cta section-cta--home">
      <div className="wrapper">
        <h2>Ready to see what we have to offer?</h2>
        <div className="cta-two-wrapper">
          <Link to="/get-started" className="cta cta--primary">Get Started</Link>
          <Link to="/services" className="cta cta--ghost">See Our Packages</Link>
        </div>
      </div>
    </section>
  </main>
);

Homepage.propTypes = {
  quotes: PropTypes.array.isRequired
};

export default Homepage;
