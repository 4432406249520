import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QuoteLeft from './quote__left.svg';
import QuoteRight from './quote__right.svg';
import QuoteBranding from './quote__branding.svg';

class Quotes extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIndex: 0
    };
  }

  goToItem (index) {
    clearInterval(this.state.intervalId);

    this.setState({
      activeIndex: index,
      intervalId: setInterval(this.nextItem.bind(this), 7000)
    });
  }

  prevItem (event) {
    let index = this.state.activeIndex;
    let { quotes } = this.props;
    let slidesLength = quotes.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index
    });

    // event.preventDefault();
  }

  nextItem (event) {
    let index = this.state.activeIndex;
    let { quotes } = this.props;
    let slidesLength = quotes.length - 1;

    if (index === slidesLength) {
      index = -1;
    }

    ++index;

    this.setState({
      activeIndex: index
    });

    // event.preventDefault();
  }

  componentDidMount () {
    let intervalId = setInterval(this.nextItem.bind(this), 7000);

    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount () {
    clearInterval(this.state.intervalId);
  }

  render () {
    return (
      <section className="quotes">
        <div className="wrapper">
          <h2>What our clients say</h2>
          <div className="quote__outer">
            {this.props.quotes.map((quote, index) =>
              <div key={index} className="quote__item" data-state={index === this.state.activeIndex ? 'active' : 'inactive'}>
                {quote.node.data.content &&
                  <blockquote dangerouslySetInnerHTML={{ __html: quote.node.data.content.html }} />
                }
                <cite>
                  {quote.node.data.name &&
                    <span className="quote__name">{quote.node.data.name.text}</span>
                  }
                  {quote.node.data.title &&
                    <span className="quote__title">{quote.node.data.title.text}</span>
                  }
                </cite>
              </div>
            )}
          </div>
          <div className="quote__control">
            {this.props.quotes.map((quote, index) =>
              <span
                key={index}
                data-state={index === this.state.activeIndex ? 'active' : 'inactive'}
                onClick={e => this.goToItem(index)}
              ></span>
            )}
          </div>
        </div>
        <img src={QuoteLeft} alt="" className="quote__left-quote" width="30" height="26" />
        <img src={QuoteRight} alt="" className="quote__right-quote" width="30" height="26" />
        <img src={QuoteBranding} alt="" className="quote__branding" width="900" height="168" />
      </section>
    );
  }
}

Quotes.propTypes = {
  quotes: PropTypes.array.isRequired
};

export default Quotes;
